
import { b64toBlob, getIOSMediaUrl } from "@evercam/shared/utils"
import SnapshotEditorActions from "@/components/SnapshotEditorActions"
import ImageEditor from "@evercam/shared/components/imageEditor/ImageEditor"
import ImageEditorToolbar from "@evercam/shared/components/imageEditor/ImageEditorToolbar"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import IosMediaDialog from "@evercam/shared/components/medias/IosMediaDialog"
import WatermarkedImageDownload from "@evercam/shared/components/WatermarkedImageDownload"

import {
  MediaCreateRequestPayload,
  MediaType,
  WatermarkedImageDownloadOrigin,
} from "@evercam/shared/types"
import Vue from "vue"
import { AnalyticsEvent } from "@evercam/shared/types/analytics"

export default Vue.extend({
  name: "SelectedImage",
  components: {
    WatermarkedImageDownload,
    IosMediaDialog,
    SnapshotEditorActions,
    ImageEditor,
    ImageEditorToolbar,
  },
  props: {
    image: {
      type: Object,
      default: null,
    },
    camera: {
      required: false,
      type: Object,
      default: () => ({}),
    },
    project: {
      required: false,
      type: Object,
      default: () => ({}),
    },
    userFullname: {
      type: String,
      default: "",
    },
    userEmail: {
      type: String,
      default: "",
    },
    token: {
      type: String,
      default: "",
    },
    isFullscreen: {
      type: Boolean,
      default: false,
    },
    showWatermark: {
      type: Boolean,
      default: false,
    },
    origin: {
      type: String,
      default: WatermarkedImageDownloadOrigin.Snapshot,
    },
    data: {
      required: false,
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isIE: false,
      isLoading: false,
      selectedImage: this.image,
      title: "",
      selectedTitle: "",
      showEditTitle: false,
      downloading: false,
      editorInstance: null,
      editorHeight: "auto",
      iosMediaDialog: false,
      iosMediaUrl: "",
      redoDisabled: true,
      undoDisabled: true,
      isCropping: false,
    }
  },
  computed: {
    currentDate() {
      return this.$moment().tz(this.project?.timezone)
    },
    formatedDate() {
      const date = new Date(this.selectedImage?.createdAt || this.currentDate)

      return this.$moment(date).tz(this.project?.timezone).format("L LT")
    },
    watermarkText() {
      const editTime = this.$moment().tz(this.project?.timezone).format("llll")
      let text = ""
      switch (this.origin) {
        case WatermarkedImageDownloadOrigin.Snapshot:
          text = `Camera Name: ${this.camera?.name} | Edited On: ${editTime}`
          break
        case WatermarkedImageDownloadOrigin.The360:
        case WatermarkedImageDownloadOrigin.Bim:
        case WatermarkedImageDownloadOrigin.Drone:
        case WatermarkedImageDownloadOrigin.SitePlanner:
          text = `Project : ${this.project?.name} | Edited On: ${editTime}`
          break
        default:
          text = `Camera Name: ${this.camera?.name} | Downloaded On: ${editTime}`
          break
      }
      if (this.userEmail) {
        text += ` | By: ${this.userFullname}`
      }

      return text
    },
    initialFilename() {
      const dateAndTime = this.$moment(
        this.selectedImage?.createdAt || this.currentDate
      )
        .tz(this.project?.timezone)
        ?.format("ddd, MMM Do YYYY, h:mm a")

      switch (this.origin) {
        case WatermarkedImageDownloadOrigin.The360:
          return `360: ${this.data.data360.selectedDate} ${this.data.data360.selectedFloorName} Marker${this.data.data360.selectedMarker}`

        case WatermarkedImageDownloadOrigin.Drone:
          return `Drone: ${this.project?.name} ${this.data.droneData.flightDate}`

        case WatermarkedImageDownloadOrigin.Bim:
          return `BIM: ${this.project?.name} ${this.data.bimData.selectedModel}`

        case WatermarkedImageDownloadOrigin.SitePlanner:
          return `Site planner: ${this.project?.name} ${
            this.data?.sitePlanData?.projectName
              ? this.data.sitePlanData.projectName
              : "Site plan"
          }`

        default:
          return `${
            this.camera?.name ? this.camera?.name : this.project?.name
          } ${dateAndTime}`
      }
    },
    pdfData() {
      return {
        project: this.project?.name,
        ...(this.origin === WatermarkedImageDownloadOrigin.Snapshot
          ? { camera: this.camera?.name }
          : {}),
        user: this.userEmail,
        time: this.$moment()
          .tz(this.project?.timezone)
          ?.format("YYYY-MM-DD HH:mm:ss"),
      }
    },
  },
  watch: {
    image(value) {
      this.selectedImage = value
    },
  },
  created() {
    if (this.isFullscreen) {
      this.$fullscreen.exit()
    }
  },
  mounted() {
    this.onResize()
    this.isIE = this.$device.isIE
  },
  beforeDestroy() {
    this.closeEditor()
  },
  methods: {
    closeEditor() {
      this.$emit("dismiss")
    },
    onResize() {
      const container = this.$refs.container as HTMLDivElement
      const toolbar = this.$refs.toolbar?.$el?.getBoundingClientRect() || {}
      const footer = this.$refs.footer?.$el?.getBoundingClientRect() || {}
      const tabs = this.$globalRefs.tabs?.getBoundingClientRect() || {}
      const containerHeight = window.innerHeight - tabs.bottom

      if (
        !containerHeight ||
        (!tabs?.bottom &&
          !this.$device.isMobile &&
          this.origin === WatermarkedImageDownloadOrigin.Snapshot) ||
        !footer?.height ||
        !toolbar?.height
      ) {
        this.$setTimeout(this.onResize, 50)

        return
      }
      container.style.height = `${containerHeight}px`
      container.style.maxHeight = `${containerHeight}px`
      this.editorHeight = `${
        containerHeight - toolbar.height - footer.height
      }px`
    },
    initEditor(instance) {
      this.editorInstance = instance
      this.selectedTitle = this.initialFilename
    },
    updateConnectorImage() {
      this.$emit("update-connector-image", this.getEditedImageData())
    },
    async download(type) {
      this.downloading = true
      const file = this.getEditedImageData()
      try {
        if (this.isIE) {
          this.downloadIEImage()
        } else if (this.$device.isIos) {
          this.iosMediaUrl = getIOSMediaUrl(file)
          this.iosMediaDialog = true
        } else {
          const title = this.selectedTitle
          let editedImage = new Image()
          editedImage.src = file
          editedImage.onload = () => {
            this.$root.$emit("download-watermarked-image", {
              image: editedImage,
              title,
              origin: this.origin,
              saveAsType: type.value,
              watermarkText: this.watermarkText,
            })
          }
        }
      } catch {
        this.downloadIEImage()
      }
    },
    downloadIEImage() {
      let editedImage = this.editorInstance?.imageSaver.asDataURL(
        "image/jpeg",
        1
      )
      const image = b64toBlob(
        editedImage.replace("data:image/jpeg;base64,", ""),
        "image/jpeg"
      )
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(image, `${this.selectedTitle}.jpeg`)
      }
      this.downloading = false
    },
    async saveToMediaHub() {
      this.isLoading = true
      const date =
        this.$moment(this.selectedImage?.createdAt || this.currentDate).format(
          "YYYY-MM-DDTHH:mm:ss.SSS"
        ) + "Z"
      const imageData = this.getEditedImageData()
      const payload: MediaCreateRequestPayload = {
        title: this.selectedTitle,
        type: MediaType.EditedImage,
        content: imageData,
        cameraExid: this.camera?.id,
        fileExtension: "jpeg",
        requestedBy: this.userEmail,
        fromDate: date,
        toDate: date,
      }
      try {
        await EvercamApi.mediaHub.cCreate(this.project.exid, payload)
        this.$notifications.success(
          this.$t("content.media_hub.save_image_success").toString()
        )
        this.$analytics.saveEvent(AnalyticsEvent.EditToolSaveToMediaHub)
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.media_hub.create_failed"),
          error,
        })
      } finally {
        this.isLoading = false
      }
    },
    getEditedImageData() {
      return this.editorInstance?.imageSaver.asDataURL("image/jpeg", 1)
    },
    updateActionStack() {
      this.$setTimeout(() => {
        this.redoDisabled = !this.editorInstance?.worklog?.current?.next
        this.undoDisabled = !this.editorInstance?.worklog?.current?.prev
      })
    },
    doCrop() {
      this.isCropping = false
      this.$analytics.saveEvent(AnalyticsEvent.EditToolApplyCrop)
      try {
        this.editorInstance?.selectTool("crop")
      } catch (e) {
        this.$errorTracker.save(["Empty crop area", e])
      }
    },
    cancelCrop() {
      this.isCropping = false
      this.$analytics.saveEvent(AnalyticsEvent.EditToolCancelCrop)
    },
    openEditTitle() {
      this.showEditTitle = !this.showEditTitle
      this.title = this.selectedTitle
      this.$analytics.saveEvent(AnalyticsEvent.EditToolOpenEditName)
    },
    cancelEditTitle() {
      this.showEditTitle = !this.showEditTitle
      this.$analytics.saveEvent(AnalyticsEvent.EditToolCancelEditName)
    },
    saveEditedTitle() {
      this.showEditTitle = !this.showEditTitle
      this.selectedTitle = this.title
      this.$analytics.saveEvent(AnalyticsEvent.EditToolSaveEditedName)
    },
  },
})
